import React, { createContext, useEffect, useState } from "react";

const defaultState = {
  cart: null,
  isCartOpen: false,
  setIsCartOpen: () => {},
  handleAddToCart: () => {},
  handleRemoveFromCart: () => {},
  updateLineItem: () => {},
  handleFetchCheckout: () => {},
  toggleCartVisiblity: () => {},
};

export const ShopifyContext = createContext(defaultState);

export const ShopifyContextProvider = ({ children }) => {
  const [cart, setCart] = useState(null);
  const [cartContents, setCartContents] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [clientReady, setClientReady] = useState(false);

  const domain = process.env.GATSBY_MYSHOPIFY_URL;
  const token = process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

  const cartFields = `
  id
  checkoutUrl
  cost {
    totalAmount {
      amount
      currencyCode
    }
  }
  lines(first: 100) {
    edges {
      node {
        id
        quantity
        merchandise {
          ... on ProductVariant {
            id
            title
            image {
              url
              altText
            }
            product {
              title
              featuredImage {
                url
                altText
              }
            }
          }
        }
      }
    }
  }
    `;

  useEffect(() => {
    if (domain && token) {
      setClientReady(true);
    } else {
      console.warn("⚠️ Shopify client not configured properly: missing domain or token.");
    }
  }, [domain, token]);

  const endpoint = domain ? `https://${domain}/api/2024-04/graphql.json` : null;

  const headers = {
    "Content-Type": "application/json",
    "X-Shopify-Storefront-Access-Token": token,
  };

  const fetchGraphQL = async (query, variables = {}) => {
    if (!clientReady || !endpoint) return null;

    const res = await fetch(endpoint, {
      method: "POST",
      headers,
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();
    return json.data;
  };

  const getCartId = () => localStorage.getItem("shopify_cart_id");
  const setCartId = (id) => localStorage.setItem("shopify_cart_id", id);

  const createCart = async () => {
    const query = `
      mutation {
        cartCreate {
          cart {
            ${cartFields}
          }
        }
      }
    `;
    const data = await fetchGraphQL(query);
    if (data?.cartCreate?.cart) {
      const newCart = data.cartCreate.cart;
      setCart(newCart);
      setCartId(newCart.id);
    }
  };

  const fetchCart = async () => {
    const id = getCartId();
    if (!id) return createCart();

    const query = `
      query ($id: ID!) {
        cart(id: $id) {
          ${cartFields}
        }
      }
    `;
    const data = await fetchGraphQL(query, { id });
    if (data?.cart) {
      setCart(data.cart);
    } else {
      createCart();
    }
  };

  const handleAddToCart = async (variantId, quantity) => {
    const id = getCartId();

    console.log("variantId", variantId);
    console.log("quantity", quantity);
    console.log("id", id);

    const query = `
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            ${cartFields}
          }
        }
      }
    `;
    const data = await fetchGraphQL(query, {
      cartId: id,
      lines: [{ merchandiseId: variantId, quantity }],
    });

    console.log("data", data);
    if (data?.cartLinesAdd?.cart) {
      setCart(data.cartLinesAdd.cart);
    }
  };

  const updateLineItem = async (lineId, quantity) => {
    const id = getCartId();
    const query = `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            ${cartFields}
          }
        }
      }
    `;
    const data = await fetchGraphQL(query, {
      cartId: id,
      lines: [{ id: lineId, quantity }],
    });
    if (data?.cartLinesUpdate?.cart) {
      setCart(data.cartLinesUpdate.cart);
    }
  };

  const handleRemoveFromCart = async (lineId) => {
    const id = getCartId();

    const query = `
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            ${cartFields}
          }
        }
      }
    `;
    const data = await fetchGraphQL(query, {
      cartId: id,
      lineIds: [lineId],
    });
    if (data?.cartLinesRemove?.cart) {
      setCart(data.cartLinesRemove.cart);
    }
  };

  const handleFetchCheckout = () => {
    if (cart?.checkoutUrl) {
      window.location.href = cart.checkoutUrl;
    }
  };

  const toggleCartVisiblity = () => {
    setIsCartOpen(!isCartOpen);
  };

  useEffect(() => {
    if (clientReady) {
      fetchCart();
    }
  }, [clientReady]);

  useEffect(() => {
    if (cart) {
      setCartContents(cart.lines.edges);
    }
  }, [cart]);

  return (
    <ShopifyContext.Provider
      value={{
        cart,
        isCartOpen,
        setIsCartOpen,
        handleAddToCart,
        handleRemoveFromCart,
        handleFetchCheckout,
        toggleCartVisiblity,
        updateLineItem,
        cartContents,
      }}>
      {children}
    </ShopifyContext.Provider>
  );
};
