import React, { useContext } from "react";
import { ShopifyContext } from "../../context/ShopifyContext";
import slugify from "../../helpers/slugify";
import CartLineItem from "./CartLineItem";

export default function Cart() {
  const { handleFetchCheckout, cart, cartContents, isCartOpen, setIsCartOpen, handleRemoveFromCart } = useContext(ShopifyContext);

  console.log(cart);
  return (
    <div className={`cart-wrapper ${isCartOpen ? "active" : ""}`}>
      <div className="cart-wrapper--inner">
        <button className="continueShopping" onClick={() => setIsCartOpen(false)}>
          &larr; Continue shopping
        </button>
        <ul className="cart-contents">{cartContents && cartContents?.map((item, index) => <CartLineItem key={`cart_contents_${slugify(item?.node?.merchandise?.title)}`} item={item} index={index} handleRemoveFromCart={handleRemoveFromCart} />)}</ul>
        <div className="cart-footer">
          <div className="totals">
            <div className="total">
              <span className="label">Total</span>
              <span className="totalNumber">{cart?.cost?.totalAmount?.amount !== undefined ? `£${parseFloat(cart?.cost?.totalAmount?.amount).toFixed(2)}` : ""}</span>
            </div>
            <small>Shipping and taxes will be calculated at checkout.</small>
          </div>
          <a className="btn btn--black" href={cart?.checkoutUrl} target="_blank" rel="noopener noreferrer">
            Proceed to checkout
          </a>
        </div>
      </div>
    </div>
  );
}
