import React, { useState, useContext } from "react";
import ShopifyProductImage from "./ShopifyProductImage";
import { ShopifyContext } from "../../context/ShopifyContext";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { useEffect } from "react";
import slugify from "../../helpers/slugify";
import { FreeMode } from "swiper";

export default function CartLineItem({ item, cartContents, handleRemoveFromCart }) {
  console.log(item);

  useEffect(() => {
    if (quantity !== item?.node?.quantity) {
      setTimeout(() => {
        setQuantity(item?.node?.quantity);
      }, 200);
    }
  }, [item]);

  const [quantity, setQuantity] = useState(item?.node?.quantity);
  const [itemTotal, setItemTotal] = useState();
  const { updateLineItem, client } = useContext(ShopifyContext);

  const handleQtyUpdate = (e) => {
    if (e.target.classList.contains("minus")) {
      setQuantity(parseInt(quantity) - 1);
    }
    if (e.target.classList.contains("plus")) {
      setQuantity(parseInt(quantity) + 1);
    }
  };

  const calculateItemTotal = () => {
    const qty = parseInt(quantity);
    const unitPrice = parseFloat(item?.node?.cost?.totalAmount?.amount);
    const itemTotal = `£${(qty * unitPrice).toFixed(2)}`;

    if (isNaN((qty * unitPrice).toFixed(2))) {
      setItemTotal("");
    } else {
      setItemTotal(itemTotal);
    }
  };

  useEffect(() => {
    calculateItemTotal();
    updateLineItem(item?.node?.id, parseInt(quantity));
  }, [quantity, cartContents]);

  if (!item || !item?.node) return null;

  return (
    <li id={`cart_contents_${slugify(item?.node?.merchandise?.title)}`} className="cart_line_item">
      <img className="cart_item_image" src={item?.node?.merchandise?.product?.featuredImage?.url} alt={item?.node?.merchandise?.product?.featuredImage?.altText} />

      <div className="cart-item-wrapper">
        <div className="cart-item--header">
          <div className="cart-item--title">
            <div className="cart-item--title--main">{item?.node?.merchandise?.product?.title}</div>
            <div className="cart-item--title--sub">{item?.node?.merchandise?.title}</div>
            {/* <div className="cart-item--title--sub">£{parseFloat(item.node.cost.totalAmount.amount).toFixed(2)}</div> */}
          </div>
          <div className="cart-item--footer">
            <div className="cart-item--qty">
              <small>Quantity</small>
              <div className="number-wrapper">
                <AiOutlineMinus className="minus icon" onClick={(e) => handleQtyUpdate(e)} />
                <input type="number" min={1} onWheel={(e) => e.target.blur()} onChange={(e) => setQuantity(e.target.value)} value={quantity} />
                <AiOutlinePlus className="plus icon" onClick={(e) => handleQtyUpdate(e)} />
              </div>
            </div>
            <div className="cart-item-total">
              {itemTotal}
              {/* {item.variant.price.amount} */}
            </div>
          </div>
          <button className="removeItem" onClick={() => handleRemoveFromCart(item?.node?.id)}>
            <BsTrash onClick={() => handleRemoveFromCart(item?.node?.id)} />
          </button>
        </div>
      </div>
    </li>
  );
}
